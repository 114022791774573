import { useEffect, useState } from 'react'
import Form from '../../../../../../components/Form'
import { DateInput as DatePicker } from '../../../../../../components/Form/date'
import { useLoading } from '../../../../../../hooks/loading'
import { useToast } from '../../../../../../hooks/toast'
import api from '../../../../../../services/api'
import { apiUpdateAbsencePeriod } from '../../domain/api'
import { FormContainer } from './styles'
import moment from 'moment'

type TypesFormProps = {
  initialValues?: IStudent
  onSuccessSubmit: () => void
}

export const FormAbsencePeriod = ({
  initialValues,
  onSuccessSubmit
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const { disableLoading, activeLoading } = useLoading()

  const [startDate, setStartDate] = useState<string | Date>()
  const [endDate, setEndDate] = useState<string | Date>()

  useEffect(() => {
    if (initialValues.absence_start && initialValues.absence_end) {
      setStartDate(
        moment(initialValues.absence_start, 'DD/MM/YYYY HH:mm:ss').toDate()
      )
      setEndDate(
        moment(initialValues.absence_end, 'DD/MM/YYYY HH:mm:ss').toDate()
      )
    }
  }, [initialValues])

  const onSubmitForm = async (dataForm: IStudent) => {
    const dataUpdate = {
      absence_end: startDate ? dataForm.absence_end : null,
      absence_start: endDate ? dataForm.absence_start : null
    }

    try {
      activeLoading()
      await api.put(
        apiUpdateAbsencePeriod(String(initialValues.id)),
        dataUpdate
      )

      disableLoading()
      addToast({
        type: 'success',
        title: 'Estudante atualizado',
        description: 'Estudante alterado com sucesso'
      })
      onSuccessSubmit()
    } catch (error: any) {
      console.log(error.response.data)
      disableLoading()
      addToast({
        type: 'error',
        title: 'Erro ao atualizar o estudante',
        description:
          'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
      })
    }
  }

  return (
    <Form onSubmit={onSubmitForm}>
      <>
        <div className="mb-5 mb-xl-10">
          <FormContainer className="form-body">
            <div className="row mb-5">
              <DatePicker
                label="Data de início"
                className="col-md-6"
                name="absence_start"
                value={startDate as string}
                selected={startDate as Date}
                onChange={date => setStartDate(date)}
                controlled
              />
              <DatePicker
                label="Data final"
                className="col-md-6"
                name="absence_end"
                selected={endDate as Date}
                value={endDate as string}
                onChange={date => setEndDate(date)}
                minDate={startDate as Date}
                controlled
              />
            </div>
          </FormContainer>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button
              type="button"
              className="btn btn-secundary"
              onClick={() => {
                setStartDate('')
                setEndDate('')
              }}
            >
              Limpar
            </button>
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </div>
      </>
    </Form>
  )
}
