/* eslint-disable @typescript-eslint/no-empty-function */
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../../components/Container'
import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { Alert } from '../../../../../components/Alert'
import { nameActions } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewDelete,
  toolsViewList,
  toolsViewUpdate
} from '../domain/tools'
import Tabs from 'components/Tabs'
import Tab from 'components/Tabs/Tab'
import DataTable from 'components/DataTable'
import ViewInfoLabel from 'components/ViewInfoLabel'
import { MenuTable, MenuTableRow } from 'pages/Menus/View/styles'
import { LinkContainer } from './styles'
import moment from 'moment'
import Modal from 'components/Modal'
import { FormAbsencePeriod } from '../components/FormAbsencePeriod'

const StudentView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const [student, setStudent] = useState<IStudent | null>(null)
  const searchParametersAuditLog = useRef([
    { entity: 'Student', entity_id: id }
  ])

  const { addToast } = useToast()
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })

  const { disableLoading, activeLoading } = useLoading()

  const getStudent = useCallback(async () => {
    activeLoading()
    try {
      const response = await api.get<IStudent>(apiList(id))
      const { data } = response
      setStudent(data)
      disableLoading()
    } catch (err) {
      const [, baseUrl, path] = location.pathname.split('/')
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar o estudante',
        description:
          'Houve um error ao carregar o estudante, tente novamente mais tarde!'
      })
      if (path.includes('view')) {
        history.push(`/${baseUrl}`)
      } else {
        history.push(`/${baseUrl}/${path}`)
      }
    }
  }, [activeLoading, addToast, disableLoading, history, id, location.pathname])

  useEffect(() => {
    getStudent()
  }, [getStudent])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Estudante removido com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title: 'Estudante não removido, pois ainda está sendo usado.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Estudante removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Estudante não removido, pois ainda está sendo usado.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }

  const [modalAbsence, setModalAbsence] = useState<{
    isActive: boolean
  }>({
    isActive: false
  })

  const onCloseModalAbsence = () => {
    getStudent()
    setModalAbsence({
      isActive: false
    })
  }

  const onSuccessModalAbsence = () => {
    getStudent()
    setModalAbsence({
      isActive: false
    })
  }

  return (
    <>
      <Container
        pageTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row mb-5">
            <ViewInfoLabel col={4} description={student?.id} label="Cód." />
            <ViewInfoLabel col={4} description={student?.name} label="Nome" />
            <ViewInfoLabel
              col={4}
              description={student?.name}
              label="Responsável"
              descriptionWithLink={
                <a href={`/commercial/clients/view/${student?.client_id}`}>
                  {student?.client?.name}
                </a>
              }
            />
          </div>
          <div className="row mb-5">
            <ViewInfoLabel
              col={4}
              description={student?.institution?.company_social_name}
              label="Instituição"
            />
            <ViewInfoLabel
              col={4}
              description={
                typeof student?.birth_date === 'string' &&
                student?.birth_date.slice(0, 10)
              }
              label="Data de nascimento"
            />
            <ViewInfoLabel col={4} description={student?.gender} label="Sexo" />
          </div>
          <div className="row mb-5">
            <ViewInfoLabel
              col={8}
              description={student?.serie_class?.name}
              label="Ano/Turma"
            />
            <ViewInfoLabel
              col={4}
              description={student?.school_period}
              label="Turno"
            />
          </div>
          <div className="row mb-5">
            <ViewInfoLabel
              col={8}
              description={student?.observation}
              label="Observação"
            />
            <ViewInfoLabel
              col={4}
              descriptionWithLink={
                <div>
                  <p>
                    {student?.absence_start && student.absence_end && (
                      <span>
                        {moment(student.absence_start, 'DD/MM/YYYY HH:mm:ss')
                          .add(3, 'h')
                          .format('DD/MM/YYYY')}
                        -{' '}
                        {moment(student.absence_end, 'DD/MM/YYYY HH:mm:ss')
                          .add(3, 'h')
                          .format('DD/MM/YYYY')}
                      </span>
                    )}
                    <span
                      className="fa fa-edit mx-2 p-2 cursor-pointer"
                      onClick={() => setModalAbsence({ isActive: true })}
                    ></span>
                  </p>
                </div>
              }
              label="Periodo ausente"
            />
          </div>
          <div className="row mb-5">
            <ViewInfoLabel
              col={4}
              description={student?.is_active === true ? 'Sim' : 'Não'}
              label="Ativo"
            />
            <ViewInfoLabel
              col={4}
              description={student?.created_at}
              label="Cadastrado em"
            />
          </div>
          <div className="separator my-5" />
          <div className="row">
            <div className="col-md-12">
              <Tabs>
                <Tab key={0} title="Contratos">
                  <div>
                    <div className="card-header p-0 d-flex - align-items-center">
                      <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                        Listagem
                      </div>
                    </div>
                    <div className="table-responsive">
                      <MenuTable
                        id="kt_datatable"
                        className="table table-striped table-row-bordered gs-3 border table-hover dataTable"
                      >
                        <thead className="border-bottom border-end">
                          <tr>
                            <th>Forma de pagamento</th>
                            <th>Valor Total</th>
                            <th>Situação</th>
                            <th>Cadastrado em</th>
                            <th style={{ textAlign: 'center' }}>Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {student?.contracts.length === 0 && (
                            <tr>
                              <td colSpan={6} className="text-center">
                                Esse estudante não possui contratos
                              </td>
                            </tr>
                          )}
                          {student?.contracts?.map(contract => {
                            return (
                              <MenuTableRow key={contract.id}>
                                <td>{contract.form_payament}</td>
                                <td>{contract.value_payament}</td>
                                <td>{contract.situation}</td>
                                <td>{contract.created_at}</td>
                                <td className="d-flex align-items-center justify-content-center">
                                  <LinkContainer>
                                    <div>
                                      <a
                                        href={`/commercial/contracts/view/${contract.id}`}
                                      >
                                        <span
                                          className="fa fa-search"
                                          style={{
                                            border: 'none',
                                            color: '#009EF7'
                                          }}
                                        />
                                      </a>
                                    </div>
                                  </LinkContainer>
                                </td>
                              </MenuTableRow>
                            )
                          })}
                        </tbody>
                      </MenuTable>
                    </div>
                  </div>
                </Tab>
                <Tab key={1} title="Restrições alimentares">
                  <div>
                    <div className="card-header p-0 d-flex - align-items-center">
                      <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                        Listagem
                      </div>
                      <div className="">
                        {/* <a
                          href="#"
                          onClick={() =>
                            handleClickOnOpenModalCreate(Number(id))
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="fa fa-plus" /> Adicionar
                        </a> */}
                      </div>
                    </div>
                    <div className="table-responsive">
                      <MenuTable
                        id="kt_datatable"
                        className="table table-striped table-row-bordered gs-3 border table-hover dataTable"
                      >
                        <thead className="border-bottom border-end">
                          <tr>
                            <th>Restrição alimentar</th>
                            <th>Cadastrado em</th>
                          </tr>
                        </thead>
                        <tbody>
                          {student?.student_food_restrictions.length === 0 && (
                            <tr>
                              <td colSpan={6} className="text-center">
                                Esse estudante não possui restrições alimentares
                              </td>
                            </tr>
                          )}
                          {student?.student_food_restrictions?.map(
                            student_restriction => {
                              return (
                                <MenuTableRow key={student_restriction.id}>
                                  <td>{student_restriction.description}</td>
                                  <td>{student_restriction.created_at}</td>
                                </MenuTableRow>
                              )
                            }
                          )}
                        </tbody>
                      </MenuTable>
                    </div>
                  </div>
                </Tab>

                <Tab title="Histórico" key={2}>
                  <div className="portlet light">
                    <div className="card-header p-0">
                      <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                        Listagem
                      </div>
                    </div>
                    <div className="portlet-body form">
                      <DataTable
                        entityId={id}
                        format={{ orderBy: '' }}
                        source="auditLogs"
                        entity="AuditLog"
                        searchParameters={searchParametersAuditLog.current}
                      />
                    </div>
                  </div>
                </Tab>
                <></>
              </Tabs>
            </div>
          </div>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir o registro ${alert.name} ?`}
        onClickCancelButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${student?.name} ?`}
        onClickCancelButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(student?.id))
        }
        isActive={alertRemoveParent}
      />
      <Modal
        isOpenModal={modalAbsence.isActive}
        onClickButtonCancel={onCloseModalAbsence}
        Children={
          <FormAbsencePeriod
            initialValues={student}
            onSuccessSubmit={onSuccessModalAbsence}
          />
        }
        pageTitle="Período ausente"
      />
    </>
  )
}

export default StudentView
